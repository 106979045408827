<template>
    <div>
		<el-table stripe border v-loading="loading" :data="dataList" >
			<el-table-column type="expand">
				<template slot-scope="scope">
					<el-form label-position="left" inline class="table-expand">
						<el-form-item :label="translate('OPER_LOG.METHOD')">
							<span>{{scope.row.method}}</span>
						</el-form-item>
						<el-form-item :label="translate('OPER_LOG.URL')">
							<span>{{scope.row.url}}</span>
						</el-form-item>
						<el-form-item :label="translate('OPER_LOG.SYSTEM')">
							<span>{{scope.row.system}}</span>
						</el-form-item>
						<el-form-item :label="translate('LOGIN_INFO.BROWSER')">
							<span>{{scope.row.browser}}</span>
						</el-form-item>
						<el-form-item :label="translate('OPER_LOG.APP_INFO')">
							<span>{{scope.row.appInfo}}</span>
						</el-form-item>
						<el-form-item :label="translate('OPER_LOG.PARAMS')">
							<span>{{scope.row.params}}</span>
						</el-form-item>
						<el-form-item :label="translate('OPER_LOG.RESULT')">
							<span>{{scope.row.result}}</span>
						</el-form-item>
					</el-form>
				</template>
			</el-table-column>
			<el-table-column :label="translate('OPER_LOG.ID')" prop="id" min-width="60" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('OPER_LOG.TITLE')" prop="title" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('OPER_LOG.BUSINESS_TYPE')" prop="businessType" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">{{dictTranslate(scope.row.businessType, dicts.typeOptions)}}</template>
			</el-table-column>
			<el-table-column :label="translate('USER.USER_ID')" prop="userId" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('USER.USER_NAME')" prop="userName" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('OPER_LOG.IP')" prop="ip" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('LOGIN_INFO.LOCATION')" prop="location" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.STATUS')" prop="status" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('OPER_LOG.MESSAGE')" prop="message" min-width="200" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('OPER_LOG.COST_TIME')" prop="costTime" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.CREATE_TIME')" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>

		</el-table>

		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>

	</div>
</template>

<script>
	import {UserApi} from '@/api';

	export default {
		name: "ToolBar",
		components: {},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			queryParams: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				pageNo: 1,
				pageSize: 10,
				loading: false,
				total: 0,
				dataList: []
			}
		},
		mounted() {
		},
		watch: {
			queryParams: function () {
				this.pageNo = 1;
				this.getList();
			},
		},
		methods: {
			async getList() {
				this.loading = true;
				let result = await UserApi.operLog.list(this.pageNo, this.pageSize, this.queryParams);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			},
			handleStatusChange(row) {
				let text = row.status === "DISABLE" ? "FROM.DISABLE" : "FROM.ENABLE";
				this.$confirm(this.translate('FROM.CONFIRM_OPERATION', {module: 'MODULE.OPER_LOG', operation: text}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.operLog.edit(row.id, {
						state: row.state
					});
					if (result.success) {
						this.getList();
					}
				}).catch(() => this.getList());
			},
			handleDelete(row){
				this.$confirm(this.translate('FROM.CONFIRM_DELETE', {module: 'MODULE.OPER_LOG', id: row.id}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.operLog.remove(row.id);
					if (result.success) {
						this.Message.success(this.translate('FROM.DELETE_SUCCESS'));
						this.getList();
					}
				});
			},
		}
	};
</script>

<style scoped>

</style>
